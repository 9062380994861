import {
  useCallback,
  useState,
  FormEvent,
  useRef,
  useEffect,
  useMemo,
} from 'react'
import IconDelete from '@haiper/icons-svg/icons/outline/delete.svg'
import { isNil, cloneDeep, omit, pick } from 'lodash-es'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import {
  generate,
  inpaint,
  generateBB8,
  repaint,
  extend,
  upscale,
  generateImg,
  generateAfc,
} from '@/service/job.service'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import Button from '@/components/button'
import {
  auth0SignInAtom,
  creationColdDownAtom,
  creationInputAtom,
  creationInputPrivateModeAtom,
  jobsRefreshKeyAtom,
  loginDialogOpenAtom,
} from '@/atoms'
import {
  GenerationParams,
  GenerationResult,
  GenerationSetting,
  PromiseOrNot,
  CreationModeEnum,
  SamCallbackData,
  InpaintingParams,
  InnerUploadFile,
  PoNVoid,
  ExtendGenerationParams,
  UpscaleGenerationParams,
  ThreeStage,
  CreationModeEnumOrUsecaseEnum,
} from '@/types'
import {
  getImageResolution,
  getVideoResolution,
  isProduction,
  preventDefault,
  cls,
  withFirebaseBucketPrefix,
  getVideoDuration,
  getNextExtendDuration,
  whisper,
} from '@/utils'
import { useClickOutside } from '@/hooks/useClickOutside'
import { toast } from '@/components/ui/use-toast'
import { Textarea } from './textarea'
import {
  AFC_REQUIRE_SAME_RESOLUTION,
  DEFAULT_COOL_DOWN_TIME,
  DEFAULT_IMAGE_RESOLUTION,
  DEFAULT_RESOLUTION,
} from '@/constants'
import CreationModePopover from './creation-mode/popover'
import {
  CreationSetting,
  DEFAULT_GENERATION_SETTING_MAP,
} from './creation-setting'
import VisibilitySetting from './visibility-setting'
import { ButtonGenerate } from './button-generate'
// import Suggestion from './suggestion'
import ChevronTop from '@haiper/icons-svg/icons/outline/chevron-large-top.svg'
import ChevronDown from '@haiper/icons-svg/icons/outline/chevron-large-down.svg'
import dayjs from 'dayjs'
import useCreationSettings from '@/hooks/useCreationSettings'
import { nanoid } from 'nanoid'
import LoginMask from '../login-mask'
import useActivePlan from '@/hooks/useActivePlan'
import useCredit from '@/hooks/useCredit'
import { useCachedSwitches } from '@/hooks/useSwitches'
import { confirm } from '../dialog'
import ModelVersionPicker from './model-version'
import {
  CreationInputControlsProps,
  MAX_LENGTH,
  placeholderMap,
} from './controls/common'
import T2vControls from './controls/t2v'
import I2vControls from './controls/i2v'
import V2vControls from './controls/v2v'
import UpscaleControls from './controls/upscale'
import ExtendControls from './controls/extend'
import T2iControls from './controls/t2i'
import CreationInputMask from './mask'
import useDefaultRecommendStyles from '@/hooks/useDefaultRecommendStyles'
import useCurrentCreationMode from '@/hooks/useCurrentCreationMode'
import useCreationSettingVisibility from '@/hooks/useCreationSettingVisibility'
import useDurationOptions from '@/hooks/useDurationOptions'

interface InputSchema {
  prompt?: string
  fileId?: string | null
  mode: CreationModeEnumOrUsecaseEnum
  uploading?: boolean
}

const modesRequiresFile: CreationModeEnumOrUsecaseEnum[] = [
  CreationModeEnum.Repaint,
  CreationModeEnum.Extend,
  CreationModeEnum.Upscale,
]

const modesRequiresKeyframe: CreationModeEnumOrUsecaseEnum[] = [
  CreationModeEnum.Animate,
  CreationModeEnum.AnimateHD,
]

export interface CreationInputProps {
  className?: string
  containerClassName?: string
  toolbarDirection?: 'up' | 'down'
  placeholder?: string
  onSubmit?: (params: GenerationParams) => PromiseOrNot<GenerationResult>
  onGenerate?: (generationId: string) => PoNVoid
}

const promptAtom = atom<string>('')
const creationSettingsAtom = atom<GenerationSetting>({})
const styleAtom = atom<string>('')
const fileTypeAtom = atom<string>('')
const fileAtom = atom<InnerUploadFile | null>(null)
const uploadingAtom = atom<boolean>(false)
const enableAfcAtom = atom<boolean>(false)
const keyframeFilesAtom = atom<ThreeStage<InnerUploadFile | null>>({
  first: null,
  second: null,
  third: null,
})
const keyframeFilesUploadingAtom = atom<ThreeStage<boolean>>({
  first: false,
  second: false,
  third: false,
})

export default function CreationInput({
  className,
  containerClassName,
  toolbarDirection,
  placeholder,
  onSubmit,
  onGenerate,
}: CreationInputProps) {
  const [creationInput, setCreationInput] = useAtom(creationInputAtom)
  const hasSignIn = useAtomValue(auth0SignInAtom)
  const {
    mode = CreationModeEnum.Create,
    creation,
    expanded,
    focusing,
  } = creationInput
  const [fileType, setFileType] = useAtom(fileTypeAtom)

  const { data: currentCreationMode } = useCurrentCreationMode()
  const [prompt, setPrompt] = useAtom(promptAtom)
  const [settings, setSettings] = useAtom(creationSettingsAtom)

  const hasLongPrompt = prompt?.length > 360

  const { data: switches } = useCachedSwitches()
  const showKeyframeConditioning =
    mode === CreationModeEnum.AnimateHD && !!switches?.keyframe_conditioning

  const setLoginDialogOpen = useSetAtom(loginDialogOpenAtom)
  const [style, setStyle] = useAtom(styleAtom)

  const { data: activePlan } = useActivePlan()
  const canGeneratePrivate = !!activePlan?.allow_private_generation

  const checkAuth = useCallback(() => {
    if (!hasSignIn) {
      setLoginDialogOpen(true)
    }
  }, [hasSignIn, setLoginDialogOpen])

  const setJobsRefreshKey = useSetAtom(jobsRefreshKeyAtom)
  const refreshJobs = useCallback(() => {
    setJobsRefreshKey(nanoid())
  }, [setJobsRefreshKey])

  const [file, setFile] = useAtom(fileAtom)

  const [loading, setLoading] = useState(false)
  const loadingRef = useRef<boolean>(false)

  const ref = useRef<HTMLFormElement | null>(null)
  const samRef = useRef<SamCallbackData>()

  const { isBelowMd } = useBreakpoint('md')

  const [uploading, setUploading] = useAtom(uploadingAtom)
  const [enableAfc, setEnableAfc] = useAtom(enableAfcAtom)

  const [keyframeFiles, setKeyframeFiles] = useAtom(keyframeFilesAtom)

  const [keyframesUploading, setKeyframesUploading] = useAtom(
    keyframeFilesUploadingAtom,
  )

  const creationInputPrivateMode = useAtomValue(creationInputPrivateModeAtom)

  const [coldDown, setColdDown] = useAtom(creationColdDownAtom)
  const { data: creationSettings } = useCreationSettings()
  const { refreshCredit } = useCredit()

  const coolDownTime =
    creationSettings?.cool_down_time ?? DEFAULT_COOL_DOWN_TIME

  const { showAspectRatio, validKeys, showStyles } =
    useCreationSettingVisibility()

  const useNumbericAspectRatio = !showAspectRatio && isProduction

  const disableInput = CreationModeEnum.Upscale === mode
  const hideTextArea = Boolean(isBelowMd && disableInput && expanded)
  const { data: recommendStyles } = useDefaultRecommendStyles()

  const promptSuffix = useMemo(() => {
    if (!showStyles) {
      return ''
    }
    const selectedStyle = recommendStyles?.find((item) => item.key === style)
    return selectedStyle?.prompt_append ?? ''
  }, [recommendStyles, style, showStyles])

  useEffect(() => {
    if (isBelowMd) {
      if (expanded) {
        disableBodyScroll(document.body)
      } else {
        enableBodyScroll(document.body)
      }
    }
  }, [expanded, isBelowMd])

  const setDurationByUrl = useCallback(
    async (url: string | undefined) => {
      if (!url) {
        // setSettings((prev) => ({ ...prev, duration: 0 }))
        return
      }
      const duration = await getVideoDuration(url)

      // TODO: this is a tmp solution
      const maxDuration = mode === CreationModeEnum.Upscale ? 12 : 4

      const formattedDuration =
        mode === CreationModeEnum.Extend
          ? getNextExtendDuration(duration)
          : Math.round(duration)

      const realDuration = Math.min(maxDuration, formattedDuration)
      if (formattedDuration > 0) {
        setSettings((prev) => ({ ...prev, duration: realDuration }))
      }
    },
    [mode, setSettings],
  )

  const fold = useCallback(() => {
    setCreationInput((prev) => {
      if (!prev.expanded) {
        return prev
      }
      return {
        ...prev,
        expanded: false,
      }
    })
  }, [setCreationInput])

  const unfold = useCallback(() => {
    setCreationInput((prev) => {
      if (prev.expanded) {
        return prev
      }
      return {
        ...prev,
        expanded: true,
      }
    })
  }, [setCreationInput])

  const clickOutsideOptions = useMemo(() => {
    return {
      ref,
      boundElements: [],
      excludeSelector: [
        '[data-component="creation-input"][data-outside="false"]',
        '[role="dialog"]',
      ],
      onClickOutside: fold,
    }
  }, [ref, fold])

  useClickOutside(clickOutsideOptions)

  useEffect(() => {
    const newFileType = currentCreationMode?.tags.includes('video')
      ? 'video'
      : currentCreationMode?.tags.includes('image')
        ? 'image'
        : ''

    setFileType(newFileType)
  }, [currentCreationMode, setFileType])

  const outputVideo: string = (creation as any)?.output_video
  const fileId = file?.fileId || outputVideo

  const hasKeyframeFile = Boolean(
    keyframeFiles.first || keyframeFiles.second || keyframeFiles.third,
  )
  const keyframeFileUploading =
    keyframesUploading.first ||
    keyframesUploading.second ||
    keyframesUploading.third

  const disableGenerate =
    loading ||
    (modesRequiresFile.includes(mode) && (!fileId || uploading)) ||
    (modesRequiresKeyframe.includes(mode) &&
      (!hasKeyframeFile || keyframeFileUploading))

  useEffect(() => {
    if (fileType === 'video') {
      setDurationByUrl(creation?.video_url ?? file?.url ?? '')
    }
  }, [file, fileType, setDurationByUrl, creation])

  const resetInput = useCallback(
    (resetPrompt = true) => {
      setFile(null)
      setKeyframeFiles({
        first: null,
        second: null,
        third: null,
      })
      if (resetPrompt) {
        setPrompt('')
      }
    },
    [setPrompt, setFile, setKeyframeFiles],
  )

  const resetSettings = useCallback(() => {
    setSettings(DEFAULT_GENERATION_SETTING_MAP[mode])
    setStyle('')
  }, [mode, setSettings, setStyle])

  const resetAll = useCallback(() => {
    resetSettings()
    resetInput(true)
    setCreationInput((prev) => ({
      ...prev,
      creation: undefined,
    }))
  }, [resetInput, resetSettings, setCreationInput])

  const startColdDown = useCallback(() => {
    setColdDown(
      dayjs()
        .add(coolDownTime + 1, 'second')
        .toDate(),
    )
  }, [setColdDown, coolDownTime])

  const hasInputError = useCallback(
    ({ mode, prompt, fileId, uploading }: InputSchema) => {
      if (
        mode === CreationModeEnum.Create ||
        mode === CreationModeEnum.CreateHD ||
        mode === CreationModeEnum.CreateImg
      ) {
        return prompt ? '' : 'Please input your prompt'
      }
      if (
        mode === CreationModeEnum.Animate ||
        mode === CreationModeEnum.AnimateHD
      ) {
        if (showKeyframeConditioning) {
          return hasKeyframeFile
            ? ''
            : keyframeFileUploading
              ? 'Please wait for the image to finish uploading'
              : 'Please upload your image'
        }
        return fileId
          ? ''
          : uploading
            ? 'Please wait for the image to finish uploading'
            : 'Please upload your image'
      }
      if (mode === CreationModeEnum.Repaint) {
        if (fileId && prompt) {
          return ''
        }
        if (!fileId) {
          return uploading
            ? 'Please wait for the video to finish uploading'
            : 'Please upload your video'
        }
        return 'Please input your prompt'
      }
    },
    [showKeyframeConditioning, hasKeyframeFile, keyframeFileUploading],
  )

  const checkKeyframeResolution = useCallback(
    async (newFile?: File) => {
      if (!AFC_REQUIRE_SAME_RESOLUTION) {
        return true
      }
      if (showKeyframeConditioning && modesRequiresKeyframe.includes(mode)) {
        const first = keyframeFiles.first
        const second = keyframeFiles.second
        const third = keyframeFiles.third
        const validFiles = [first, second, third].filter((item) => item?.fileId)

        let targetWidth = validFiles[0]?.width
        let targetHeight = validFiles[0]?.height

        if (newFile) {
          const url = URL.createObjectURL(newFile)
          const { width, height } = await getImageResolution(url)
          targetWidth = width
          targetHeight = height
        }

        if (
          validFiles.some(
            (item) =>
              item?.width !== targetWidth || item?.height !== targetHeight,
          )
        ) {
          confirm({
            title: 'Image dimensions mismatch',
            message:
              'Please ensure that all uploaded images have the same dimensions.',
            okText: 'Confirm',
            cancelButtonProps: {
              className: 'hidden',
            },
          })
          return false
        }
        return true
      }
      return true
    },
    [showKeyframeConditioning, keyframeFiles, mode],
  )

  useEffect(() => {
    checkKeyframeResolution().catch(console.error)
  }, [checkKeyframeResolution])

  const handleSubmit = useCallback(
    async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault()
      if (loadingRef.current === true) {
        return
      }
      const inputError = hasInputError({
        mode,
        prompt: prompt,
        fileId,
        uploading,
      })

      if (inputError) {
        toast({
          title: inputError,
        })
        return
      }

      const params: GenerationParams = cloneDeep({
        prompt: [prompt, promptSuffix].filter(Boolean).join(', '),
        settings,
        is_public: !canGeneratePrivate || !creationInputPrivateMode,
      })

      if (fileType === 'video' && creation?.creation_id) {
        params.parent_id = creation.creation_id
      }

      if (!currentCreationMode?.tags?.includes('text')) {
        delete params.prompt
      }

      if (file?.fileId && ['video', 'image'].includes(fileType)) {
        params.config =
          fileType === 'video'
            ? {
                source_video: withFirebaseBucketPrefix(file?.fileId),
              }
            : {
                source_image: withFirebaseBucketPrefix(file?.fileId),
              }

        params.config.input_content_type = fileType
        if (fileType === 'video') {
          const { width, height } = await getVideoResolution(file.url ?? '')
          params.config.input_width = width
          params.config.input_height = height
        } else {
          const { width, height } = await getImageResolution(file.url ?? '')
          params.config.input_width = width
          params.config.input_height = height
        }
      } else {
        params.config = {
          ...creation?.config,
        }
        if (mode === CreationModeEnum.Repaint) {
          params.config = {
            source_video: outputVideo ?? '',
          }
        }
      }

      const useSam =
        mode === CreationModeEnum.Repaint &&
        samRef.current &&
        (samRef.current.clicks ?? [])?.length > 0
      if (mode === CreationModeEnum.Repaint) {
        if (useSam) {
          params.config = params.config ?? {}
          const clicks = samRef.current?.clicks ?? []
          const inpaintParams = params as InpaintingParams
          inpaintParams.config!.clicks = [
            clicks.map((item) => [
              // item.x,
              // item.y,
              Math.round(item.x),
              Math.round(item.y),
            ]), // coords
            clicks.map((item) => item.clickType), // clickType
          ]

          // use converted_video if available
          inpaintParams.config!.source_video =
            samRef.current?.converted_video ??
            inpaintParams.config!.source_video
        } else {
          delete params.config.sam
          // @ts-ignore
          delete params.config.clicks
        }
      }

      if (useNumbericAspectRatio && params.settings) {
        params.settings.aspect_ratio = (16 / 9) as any
      }

      if (modesRequiresKeyframe.includes(mode) && showKeyframeConditioning) {
        params.config = params.config ?? {}
        if (enableAfc) {
          delete params.config.source_image
          delete params.config.input_content_type
          params.config.source_images = [
            keyframeFiles.first?.fileId ?? null,
            keyframeFiles.second?.fileId ?? null,
            keyframeFiles.third?.fileId ?? null,
          ]

          const checkRes = await checkKeyframeResolution()
          if (!checkRes) {
            return
          }

          const first = keyframeFiles.first
          const second = keyframeFiles.second
          const third = keyframeFiles.third
          const validFiles = [first, second, third].filter(
            (item) => item?.fileId,
          )
          params.config.input_width = validFiles[0]?.width
          params.config.input_height = validFiles[0]?.height
        } else {
          params.config.source_image = keyframeFiles.first?.fileId ?? null
        }
      }

      setLoading(true)
      loadingRef.current = true
      let generation: GenerationResult | null = null
      try {
        if (onSubmit) {
          generation = await onSubmit(params)
        } else {
          if (mode === CreationModeEnum.Repaint) {
            if (useSam) {
              generation = await inpaint(params as InpaintingParams)
            } else {
              generation = await repaint(params)
            }
          } else if (
            mode === CreationModeEnum.Create ||
            mode === CreationModeEnum.Animate
          ) {
            generation = await generate(params)
          } else if (CreationModeEnum.CreateHD === mode) {
            generation = await generateBB8(params)
          } else if (CreationModeEnum.AnimateHD === mode) {
            if (enableAfc) {
              generation = await generateAfc(params)
            } else {
              generation = await generateBB8(params)
            }
          } else if (CreationModeEnum.Extend === mode) {
            const extendParams = omit(params, [
              'settings',
            ]) as ExtendGenerationParams
            extendParams.config = {
              source_video: withFirebaseBucketPrefix(file?.fileId ?? '') ?? '',
              extend_duration: getNextExtendDuration(
                params.settings?.duration ?? 0,
              ),
            }
            generation = await extend(extendParams)
          } else if (CreationModeEnum.Upscale === mode) {
            generation = await upscale(params as UpscaleGenerationParams)
          } else if (mode === CreationModeEnum.CreateImg) {
            delete params.settings?.motion_level
            // delete params.settings?.resolution
            generation = await generateImg(params)
          }
        }
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
      loadingRef.current = false
      if (!isNil(generation?.generation_id)) {
        refreshJobs()
        await onGenerate?.(generation?.generation_id ?? '')
        startColdDown()
      }
      if (refreshCredit) {
        await refreshCredit()
      }
    },
    [
      onSubmit,
      prompt,
      mode,
      settings,
      useNumbericAspectRatio,
      fileId,
      creation,
      outputVideo,
      checkKeyframeResolution,
      onGenerate,
      fileType,
      uploading,
      startColdDown,
      refreshJobs,
      creationInputPrivateMode,
      canGeneratePrivate,
      refreshCredit,
      keyframeFiles,
      hasInputError,
      showKeyframeConditioning,
      currentCreationMode,
      enableAfc,
      file,
      promptSuffix,
    ],
  )

  useEffect(() => {
    // reset file and prompt when mode changed
    if (
      creation &&
      creation.type === 'generation' &&
      creation.output_type === 'image' &&
      creationInput.img
    ) {
      const newFile: InnerUploadFile = {
        thumbnailUrl: creationInput.img || '',
        url: creationInput.img || '',
        fileId: creationInput.img,
      }

      if (showKeyframeConditioning) {
        setKeyframeFiles({
          first: newFile,
          second: null,
          third: null,
        })
      } else {
        setFile(newFile)
      }

      getImageResolution(newFile.url)
        .then(({ width, height }) => {
          const newFileWithResolution = {
            ...newFile,
            width,
            height,
          }
          if (showKeyframeConditioning) {
            setKeyframeFiles((old) => {
              if (old?.first && old?.first === newFile) {
                return {
                  first: newFileWithResolution,
                  second: null,
                  third: null,
                }
              }
              return old
            })
          } else {
            setFile(newFileWithResolution)
          }
        })
        .catch(console.error)
      // Todo complete image2Video
    } else {
      // resetInput()
    }
  }, [
    creation,
    resetInput,
    setKeyframeFiles,
    setFile,
    creationInput.img,
    showKeyframeConditioning,
  ])

  const durationOptions = useDurationOptions()

  const updateSettings = useCallback(
    (settings: GenerationSetting) => {
      const defaultSettings = DEFAULT_GENERATION_SETTING_MAP[mode]
      const newSettings = {
        ...defaultSettings,
        ...(pick(settings, validKeys) as GenerationSetting),
      }
      if (mode === CreationModeEnum.Repaint && creation?.settings?.duration) {
        newSettings.duration = Math.min(4, creation?.settings?.duration) // TODO: refactor duration
      }

      newSettings.duration = durationOptions.some(
        (item) => item.second === newSettings.duration,
      )
        ? newSettings.duration
        : defaultSettings.duration

      if (validKeys.includes('duration')) {
        newSettings.duration = newSettings.duration || defaultSettings.duration
      } else {
        delete newSettings.duration
      }

      if (mode === CreationModeEnum.CreateImg) {
        // resolution max 1080p
        newSettings.resolution = DEFAULT_IMAGE_RESOLUTION
      } else if (newSettings.resolution && newSettings.resolution > 720) {
        newSettings.resolution = DEFAULT_RESOLUTION
      }

      setSettings(newSettings)
    },
    [mode, creation, durationOptions, setSettings, validKeys],
  )

  useEffect(() => {
    if (creation?.settings) {
      updateSettings(omit(creation.settings, ['seed']))
      // updateSettings(creation.settings)
    }
  }, [creation])

  useEffect(() => {
    updateSettings(settings)
  }, [mode])

  const renderControls = () => {
    const controlsProps: CreationInputControlsProps = {
      uploading,
      setUploading,
      expanded: Boolean(expanded),
      keyframeFiles,
      setKeyframeFiles,
      fileId,
      file,
      setFile,
      creation,
      onSamChange,
      onDeleteFile,
      textarea,
      keyframesUploading,
      setKeyframesUploading,
      enableAfc,
      setEnableAfc,
      beforeUpload: checkKeyframeResolution,
    }

    const mode2ControlsMap: Record<
      CreationModeEnumOrUsecaseEnum,
      typeof T2vControls
    > = {
      [CreationModeEnum.Create]: T2vControls,
      [CreationModeEnum.CreateHD]: T2vControls,
      [CreationModeEnum.Animate]: I2vControls,
      [CreationModeEnum.AnimateHD]: I2vControls,
      [CreationModeEnum.CreateImg]: T2iControls,
      [CreationModeEnum.Repaint]: V2vControls,
      [CreationModeEnum.Extend]: ExtendControls,
      [CreationModeEnum.Upscale]: UpscaleControls,
    }

    const ControlsComponent = mode2ControlsMap[mode]
    if (Boolean(ControlsComponent)) {
      return <ControlsComponent {...controlsProps} />
    }
    return null
  }

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (!textareaRef.current || !focusing) return
    setTimeout(() => {
      textareaRef.current?.focus()
    }, 100)
  }, [focusing])

  const onSamChange = useCallback(
    (data?: SamCallbackData) => {
      samRef.current = data

      const useSam = samRef.current && (samRef.current.clicks ?? [])?.length > 0
      if (useSam) {
        // TODO: refactor duration
        setSettings((prev) => ({
          ...prev,
          duration: Math.min(2, prev.duration ?? 4),
        }))
      }
    },
    [setSettings],
  )

  const onDeleteFile = useCallback(() => {
    if (outputVideo) {
      setCreationInput((prev) => ({ ...prev, creation: undefined }))
    } else {
      resetInput(false)
    }
  }, [outputVideo, resetInput, setCreationInput])

  useEffect(() => {
    samRef.current = undefined
  }, [fileId])

  const ChevronIcon = expanded ? ChevronDown : ChevronTop

  const textarea = useMemo(
    () => (
      <Textarea
        ref={textareaRef}
        value={prompt}
        disabled={disableInput && !isBelowMd}
        placeholder={
          disableInput
            ? ''
            : placeholder ??
              // (isBelowMd ? placeholderMap[mode]?.short : placeholderMap[mode]?.full) ??
              placeholderMap[mode]?.short ??
              'Describe the scene of the video you want'
        }
        aria-label='creation-input-textarea'
        className={cls(
          className,
          isBelowMd && !expanded ? 'pl-4 pr-0' : '',
          hideTextArea ? 'hidden' : '',
        )}
        onChange={(e: any) => setPrompt(e.target.value.slice(0, MAX_LENGTH))}
        onInput={(e) => {
          if (prompt.length === MAX_LENGTH) {
            toast({
              title: `Up to ${MAX_LENGTH} characters`,
            })
          }
        }}
        onFocus={() => {
          if (!hasSignIn) {
            checkAuth()
            return
          }
          setCreationInput((prev) => ({
            ...prev,
            focusing: true,
            expanded: true,
          }))
          // track('input:creation:focus')
        }}
        onBlur={() => {
          setCreationInput((prev) => ({ ...prev, focusing: false }))
          // track('input:creation:blur')
        }}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e?.preventDefault()
            if (!coldDown || dayjs().isAfter(dayjs(coldDown))) {
              await handleSubmit().catch(console.error)
            }
          }
        }}
      />
    ),
    [
      checkAuth,
      className,
      coldDown,
      disableInput,
      expanded,
      handleSubmit,
      setPrompt,
      hideTextArea,
      isBelowMd,
      mode,
      placeholder,
      setCreationInput,
      prompt,
      hasSignIn,
    ],
  )

  return (
    <form
      ref={ref}
      className={cls(
        'relative backdrop-blur-[25px] bg-surface md:min-w-[642px] w-full max-w-[1000px] md:max-w-[calc(min(1000px,90%))] max-h-[calc(100vh-240px)] md:max-h-[calc(100vh-160px)] rounded-t-lg md:rounded-[24px] mx-auto flex flex-col pt-0 border-2 border-b-0 md:border-b-2 border-solid border-border overflow-hidden pointer-events-auto',
        containerClassName,
      )}
      data-testid='creation-input'
      onSubmit={preventDefault as any}
    >
      <div className='relative p-3' aria-label='creation input header'>
        <div
          className='relative size-full flex items-center justify-between'
          aria-label='creation input header content'
        >
          <div className='flex items-center gap-2'>
            <CreationModePopover
              side={isBelowMd || hasLongPrompt ? 'right' : undefined}
              className={cls(
                'shrink-0 h-10 rounded-[24px] ml-0 shadow-none hover:opacity-90 active:opacity-90 bg-transparent border border-b-2 border-border',
              )}
            />
            <ModelVersionPicker />
          </div>
          <div className='absolute right-0 top-0 flex flex-col h-full justify-start items-start'>
            <Button
              variant='outline'
              className='size-8 min-w-8 min-h-8 max-h-8 border border-border rounded-md flex items-center justify-center p-0'
              tooltip='Clear'
              tooltipProps={{
                side: 'bottom',
                align: 'end',
              }}
              onClick={resetAll}
            >
              <IconDelete className='size-5 text-icon' />
            </Button>
          </div>
          <Button
            className={cls(
              'absolute border-none top-0 left-[50%] translate-x-[-50%] text-text-subdued bg-transparent w-16 h-5 p-0 m-0 hover:bg-surface-subdued rounded-full',
              expanded ? 'flex' : 'hidden',
            )}
            type='button'
            variant='transparent'
            aria-label='Toggle Expand'
            aria-expanded={expanded}
            onClick={fold}
          >
            <ChevronIcon className='size-6 text-icon-subdued' />
          </Button>
        </div>
      </div>
      <div
        className={cls(
          'flex pb-0 w-full min-h-0 overflow-y-auto no-scrollbar overscroll-contain bg-surface-base border-border border-dashed',
          toolbarDirection === 'up' ? 'flex-col' : 'flex-col-reverse',
        )}
        aria-label='creation input textarea container'
      >
        {renderControls()}
        <div
          className={cls(
            'flex flex-row items-center p-3 pt-1 bg-surface gap-3',
          )}
        >
          <CreationSetting
            settings={settings}
            setSettings={setSettings}
            style={style}
            setStyle={setStyle}
          />
          <div className='ml-auto'>
            <VisibilitySetting />
          </div>
          <ButtonGenerate
            className=''
            coldDown={coldDown}
            disabled={disableGenerate}
            loading={loading}
            settings={settings}
            creationInput={creationInput}
            hideFreePrice={(
              [
                CreationModeEnum.Extend,
                CreationModeEnum.Upscale,
              ] as CreationModeEnumOrUsecaseEnum[]
            ).includes(mode)}
            onClick={() => handleSubmit?.()}
          />
        </div>
      </div>
      <LoginMask className='hidden md:flex' />
      {expanded ? null : <CreationInputMask onClick={unfold} />}
    </form>
  )
}
