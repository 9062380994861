import { cls } from '@/utils'
import Button from '@/components/button'
import IconButton from '@/components/icon-button'
import { SamToolbarProps } from '@/types'
import useAmplitude from '@/hooks/useAmplitude'
import IconRegion from '@haiper/icons-svg/icons/outline/resize-big.svg'
import IconMinimize from '@haiper/icons-svg/icons/outline/minimize.svg'
import IconPlusOutline from '@haiper/icons-svg/icons/outline/plus-circle.svg'
import IconPlusSolid from '@haiper/icons-svg/icons/solid/plus-circle.svg'
import IconMinusOutline from '@haiper/icons-svg/icons/outline/minus-circle.svg'
import IconMinusSolid from '@haiper/icons-svg/icons/solid/minus-circle.svg'
import IconRenew from '@haiper/icons-svg/icons/outline/renew.svg'
import IconUndo from '@haiper/icons-svg/icons/outline/undo.svg'
import IconRedo from '@haiper/icons-svg/icons/outline/redo.svg'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-small.svg'
import { useCachedSwitches } from '@/hooks/useSwitches'

export interface ButtonVaryRegionProps {
  className?: string
  disabled?: boolean
  varying?: boolean
  toolbar?: SamToolbarProps
  onClick?: () => void
  onClose?: () => void
}

export function ButtonVaryRegion({
  className,
  disabled,
  varying,
  toolbar,
  onClick,
  onClose,
}: ButtonVaryRegionProps) {
  const { track } = useAmplitude()
  const { data: switches } = useCachedSwitches()
  const disableSam = !switches?.inpainting

  const iconButtonClassName =
    'size-6 min-w-6 h-6 p-0 border-none rounded-md opacity-100 bg-transparent hover:bg-surface-hover active:bg-surface-transparent text-icon overflow-hidden'
  const disabledButtonStyle =
    'hover:bg-transparent text-icon-disabled cursor-not-allowed'
  const iconClassName = 'size-5 hover:bg-surface-hover'

  if (disabled && !toolbar) {
    return (
      <Button
        disabled
        type='button'
        className={cls(
          'px-3 py-2 rounded-[360px] bg-surface shadow-sm border-border-disable border border-solid',
          disabledButtonStyle,
          className,
          disableSam ? 'hidden' : '',
        )}
      >
        <div className='flex items-center gap-1'>
          <IconRegion className='opacity-100 group-hover:opacity-100' />
          <span>Vary Region</span>
        </div>
      </Button>
    )
  }

  if (varying && toolbar) {
    const { mode, setMode, canRedo, canReset, canUndo, redo, reset, undo } =
      toolbar
    const IconPlus = mode === 'add' ? IconPlusSolid : IconPlusOutline
    const IconMinus = mode === 'remove' ? IconMinusSolid : IconMinusOutline
    return (
      <div
        className={cls(
          'p-1 md:px-3 h-10 rounded-[360px] text-body-md text-text-interactive bg-surface border-none flex flex-row justify-between items-center w-full shadow-sm',
          className,
        )}
      >
        <IconMinimize className='text-text-subdued' />
        <div className='flex'>
          <span className='ml-4 px-1 flex flex-row items-center gap-6'>
            <IconButton
              type='button'
              label='Add Mask'
              variant='transparent'
              className={cls(
                iconButtonClassName,
                mode === 'add' ? 'text-text-interactive' : 'text-icon',
                disabled ? disabledButtonStyle : '',
              )}
              disabled={disabled}
              onClick={() => {
                setMode?.('add')
                track('click:creation:vary-region-toggle-mode', { mode: 'add' })
              }}
            >
              <IconPlus className={iconClassName} />
            </IconButton>
            <IconButton
              type='button'
              label='Remove Area'
              variant='transparent'
              className={cls(
                iconButtonClassName,
                mode === 'remove' ? 'text-text-interactive' : 'text-icon',
                disabled ? disabledButtonStyle : '',
              )}
              disabled={disabled}
              onClick={() => {
                setMode?.('remove')
                track('click:creation:vary-region-toggle-mode', {
                  mode: 'remove',
                })
              }}
            >
              <IconMinus className={iconClassName} />
            </IconButton>
          </span>
          <span className='ml-3 px-1 flex flex-row items-center gap-4'>
            <IconButton
              type='button'
              label='Reset'
              variant='transparent'
              className={cls(
                iconButtonClassName,
                !canReset || disabled ? disabledButtonStyle : '',
              )}
              disabled={!canReset || disabled}
              onClick={() => {
                track('click:creation:vary-region-reset')
                reset?.()
              }}
            >
              <IconRenew className={iconClassName} />
            </IconButton>
            <span className='w-0 border border-border border-solid h-4 shrink-0 bg-surface-subdued'></span>
            <IconButton
              type='button'
              label='Undo'
              variant='transparent'
              className={cls(
                iconButtonClassName,
                !canUndo || disabled ? disabledButtonStyle : '',
              )}
              disabled={!canUndo || disabled}
              onClick={() => {
                track('click:creation:vary-region-undo')
                undo?.()
              }}
            >
              <IconUndo className={iconClassName} />
            </IconButton>
            <IconButton
              type='button'
              label='Redo'
              variant='transparent'
              className={cls(
                iconButtonClassName,
                !canRedo || disabled ? disabledButtonStyle : '',
              )}
              disabled={!canRedo || disabled}
              onClick={() => {
                track('click:creation:vary-region-redo')
                redo?.()
              }}
            >
              <IconRedo className={iconClassName} />
            </IconButton>
          </span>
        </div>
        <IconButton
          type='button'
          label='Close'
          title='Close'
          variant='transparent'
          className={cls('ml-2 md:ml-3', iconButtonClassName)}
          onClick={onClose}
        >
          <IconClose className={iconClassName} />
        </IconButton>
      </div>
    )
  }

  return (
    <Button
      type='button'
      className={cls(
        'px-3 py-2 rounded-[360px] text-text border shadow-sm',
        className,
        disableSam ? 'hidden' : '',
      )}
      onClick={onClick}
    >
      <div className='flex items-center gap-1'>
        <IconRegion className='opacity-100 group-hover:opacity-100 text-icon' />
        <span>Vary Region</span>
      </div>
    </Button>
  )
}

export default ButtonVaryRegion
