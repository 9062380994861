import { useEffect, useMemo, useState } from 'react'
import useSamVary from '@/hooks/useSamVary'
import { cls } from '@/utils'
import { SamCallbackData, PoNVoid } from '@/types'
import Sam from '@/components/sam'
import IconButton from '@/components/icon-button'
import Button from '@/components/button'
import useAmplitude from '@/hooks/useAmplitude'
import ButtonVaryRegion from '../button-vary-region'
import SamPrompt from '../sam-prompt'
import IconTrash from '@/public/assets/trash.svg'
import Loading from '@/components/loading'
import { useCachedSwitches } from '@/hooks/useSwitches'

interface SamWrapperProps {
  className?: string
  fileId: string
  thumbnailUrl?: string
  onChange: (data?: SamCallbackData) => PoNVoid
  onDelete: () => void
}

export function SamWrapper({
  className,
  fileId,
  thumbnailUrl,
  onChange,
  onDelete,
}: SamWrapperProps) {
  const [varying, setVarying] = useState(false)
  const { track } = useAmplitude()
  const { data: switches } = useCachedSwitches()
  const showSAM = !!switches?.sam

  const samVaryParams = useMemo(() => {
    return {
      fileId: varying ? fileId : '',
      onChange: varying ? onChange : undefined,
    }
  }, [varying, fileId, onChange])

  const { samProps, toolbarProps, loading, firstFrameLoading, error, retry } =
    useSamVary(samVaryParams)

  const hasError = Boolean(error)

  useEffect(() => {
    setVarying(false)
  }, [fileId])

  useEffect(() => {
    if (varying) return
    onChange?.(undefined)
  }, [varying, onChange])

  const realLoading = firstFrameLoading || loading

  useEffect(() => {
    track('click:creation:vary-region-usage-popup', { file_id: fileId })
  }, [track, fileId])

  return (
    <div
      className={cls(
        'flex flex-col justify-center gap-3 md:gap-2 mx-auto w-full md:w-auto',
        className,
      )}
    >
      <div
        className={cls(
          'bg-surface md:bg-white/5 relative rounded-lg overflow-hidden mx-auto size-full aspect-video flex items-center border border-border',
          varying && !realLoading
            ? 'md:w-[642px] h-auto max-h-[362px] md:h-[362px]'
            : 'md:w-[302px] h-auto max-h-[182px] md:h-[182px]',
        )}
      >
        <Sam
          className={cls(
            'size-full',
            varying && !realLoading && !hasError ? 'visible' : 'hidden',
          )}
          {...samProps}
        />
        {/* loading */}
        <div
          className={cls(
            'size-full bg-surface backdrop-blur-[25px] flex flex-col items-center justify-center border border-border border-solid rounded-lg',
            varying && (realLoading || hasError) ? 'visible' : 'hidden',
          )}
        >
          {/* loading */}
          <Loading className={cls('', hasError ? 'hidden' : 'visible')} />
          <div
            className={cls(
              'text-body-md text-text-subdued mt-2',
              hasError ? 'hidden' : 'visible',
            )}
          >
            Loading the region info
          </div>
          {/* error */}
          <div
            className={cls(
              'text-body-md text-text-subdued mb-2',
              hasError ? 'visible' : 'hidden',
            )}
          >
            Failed to load
          </div>
          <Button
            className={cls(
              'rounded-lg text-text',
              hasError ? 'visible' : 'hidden',
            )}
            type='button'
            variant='outline'
            onClick={(e) => {
              track('click:creation:vary-region-retry', {
                file_id: fileId,
                error: error?.message,
              })
              retry()
            }}
          >
            Retry
          </Button>
        </div>
        {thumbnailUrl ? (
          <img
            src={thumbnailUrl}
            className={cls(
              // 'size-full object-contain',
              'size-full md:h-auto max-h-full object-contain',
              varying ? 'hidden' : 'visible',
            )}
            alt=''
          />
        ) : null}
        <IconButton
          className={cls(
            'absolute rounded-sm p-0 shrink-0 border-none bg-transparent hover:bg-transparent active:bg-transparent hover:opacity-90 active:opacity-90',
            varying ? 'size-6 min-w-6 max-w-6 h-6' : 'size-4 min-w-4 h-4',
            'top-2 right-1',
          )}
          type='button'
          label='Remove video'
          side='bottom'
          sideOffset={4}
          onClick={() => {
            track('click:creation:vary-region-delete-file', {
              file_id: fileId,
            })
            onDelete()
          }}
        >
          <IconTrash
            className={cls('text-icon', varying ? 'size-6' : 'size-4')}
          />
        </IconButton>
      </div>
      <div
        className={cls(
          'flex relative md:static justify-center',
          showSAM ? '' : 'invisible h-4',
        )}
      >
        <ButtonVaryRegion
          varying={varying}
          className={cls('', varying ? 'ml-0 md:ml-auto' : '')}
          toolbar={toolbarProps}
          onClick={() => {
            setVarying(!varying)
            track('click:creation:vary-region-start', { file_id: fileId })
          }}
          onClose={() => {
            track('click:creation:vary-region-abort')
            toolbarProps.reset?.()
            setVarying(false)
          }}
        />
        <SamPrompt
          triggerClassName={cls(
            'md:bottom-4',
            varying ? 'relative md:absolute' : '',
          )}
        />
      </div>
    </div>
  )
}
