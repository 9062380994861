import { CreationMode } from '@/types'
import useCreationModes from './useCreationModes'
import { useAtomValue } from 'jotai'
import { creationInputAtom } from '@/atoms'

export default function useCurrentCreationMode(): {
  data: CreationMode | null
  loading: boolean
} {
  const { data: creationModeOptions } = useCreationModes()
  const { mode } = useAtomValue(creationInputAtom)
  const result = creationModeOptions.find((option) => option.mode === mode)

  return {
    data: result ?? null,
    loading: false,
  }
}
