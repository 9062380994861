import { creationInputAtom } from '@/atoms'
import { CreationModeEnum } from '@/types'
import { useAtom } from 'jotai'
import { useMemo } from 'react'

const useCreationSettingVisibility = () => {
  const [{ mode = CreationModeEnum.Create }] = useAtom(creationInputAtom)

  const showMotionLevel = [
    CreationModeEnum.Create,
    CreationModeEnum.Animate,
  ].includes(mode as any)

  const showDuration = [
    CreationModeEnum.Create,
    CreationModeEnum.Animate,
    CreationModeEnum.CreateHD,
    CreationModeEnum.AnimateHD,
  ].includes(mode as any)

  const showResolution = [
    CreationModeEnum.CreateImg,
    CreationModeEnum.Create,
    CreationModeEnum.Animate,
    CreationModeEnum.CreateHD,
    CreationModeEnum.AnimateHD,
  ].includes(mode as any)

  const showAspectRatio = [
    CreationModeEnum.CreateHD,
    CreationModeEnum.CreateImg,
  ].includes(mode as any)

  const showStyles = [
    CreationModeEnum.Create,
    CreationModeEnum.CreateHD,
    CreationModeEnum.CreateImg,
  ].includes(mode as any)

  const showSeed = true

  const validKeys: string[] = useMemo(() => {
    return [
      showMotionLevel && 'motion_level',
      showDuration && 'duration',
      showResolution && 'resolution',
      showAspectRatio && 'aspect_ratio',
      showSeed && 'seed',
    ].filter(Boolean) as string[]
  }, [showMotionLevel, showDuration, showResolution, showAspectRatio, showSeed])

  return {
    showMotionLevel,
    showDuration,
    showResolution,
    showAspectRatio,
    showStyles,
    validKeys,
  }
}

export default useCreationSettingVisibility
