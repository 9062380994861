import { useState } from 'react'
import { cls } from '@/utils'
import Popover, { PopoverProps } from '@/components/popover'
import { CreationModeList } from './list'
import { ButtonCreationMode, ButtonCreationModeProps } from './button'

interface CreationModeDialogProps extends ButtonCreationModeProps {
  align?: PopoverProps['align']
  side?: PopoverProps['side']
}

export default function CreationModeDialog({
  align,
  side,
  ...props
}: CreationModeDialogProps) {
  const [open, setOpen] = useState(false)
  return (
    <Popover
      open={open}
      trigger={<ButtonCreationMode {...props} />}
      align={align}
      side={side}
      className=''
      onOpenChange={setOpen}
    >
      <CreationModeList
        inPopover
        className={cls('h-full overflow-y-hidden')}
        listClassName='min-h-0 overflow-y-auto no-scrollbar md:px-0 md:py-0 w-full'
        onSelectMode={() => setOpen(false)}
      />
    </Popover>
  )
}
