import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import Link from '@/components/link'
import Checkbox from '@/components/checkbox'
import {
  showCreationsAtom,
  showModeListAtom,
  creationInputAtom,
  subscriptionDialogContentAtom,
} from '@/atoms'
import useAmplitude from '@/hooks/useAmplitude'
import { useCallback } from 'react'
import { cls, stopPropagation, whisper } from '@/utils'
import BadgeNew from '@/components/badges/new'
import useActivePlan from '@/hooks/useActivePlan'
import BadgeUpdate from '@/components/badges/update'
import useCreationModes from '@/hooks/useCreationModes'

interface CreationModeListProps {
  className?: string
  listClassName?: string
  inPopover?: boolean
  onSelectMode?: () => void
}

export function CreationModeList({
  className,
  listClassName,
  onSelectMode,
  inPopover,
}: CreationModeListProps) {
  const showCreations = useAtomValue(showCreationsAtom)
  const setShowModeList = useSetAtom(showModeListAtom)
  const [{ mode }, setCreationInput] = useAtom(creationInputAtom)
  const { track } = useAmplitude()
  const { data: activePlan, loading: activePlanLoading } = useActivePlan()
  const isFreePlan = (activePlan?.is_free || !activePlan) && !activePlanLoading
  const setSubscriptionDialogContent = useSetAtom(subscriptionDialogContentAtom)
  const { data: creationModeOptions } = useCreationModes()

  const handleModeChange = useCallback(
    (option: (typeof creationModeOptions)[number]) => {
      if (isFreePlan && option.membersOnly) {
        setSubscriptionDialogContent({
          message: `Upgrade to Haiper Membership to access the ${option.name} feature.`,
        })
        return
      }

      const value = option.mode

      if (showCreations) {
        setShowModeList(false)
      }
      setCreationInput((prev) => ({
        ...prev,
        creation: undefined,
        mode: value,
        expanded: true,
        focusing: true,
      }))
      onSelectMode?.()
      track('input:creation:change-mode', {
        mode: value,
      })
    },
    [
      onSelectMode,
      setCreationInput,
      setShowModeList,
      showCreations,
      track,
      isFreePlan,
      setSubscriptionDialogContent,
    ],
  )

  return (
    <article
      className={cls(
        'max-w-[308px] xs:max-w-[636px] md:max-w-[960px] mx-auto flex flex-col justify-center items-center w-full',
        className,
      )}
      data-component='creation-input'
      data-outside='false'
    >
      {inPopover ? (
        <span className='text-body-lg tracking-32 w-full mb-2'>
          Creation Mode
        </span>
      ) : (
        <header className='md:flex flex-col justify-center items-center mb-8'>
          <h1 className='text-center mb-0 sm:text-heading-4xl text-heading-2xl text-text font-bold font-sn'>
            Choose a template to create your own video
          </h1>
        </header>
      )}
      <section
        className={cls(
          'max-w-[308px] xs:max-w-[636px] md:max-w-full px-8',
          inPopover ? 'max-w-full px-0' : '',
          listClassName,
        )}
      >
        <div
          className={cls(
            'grid grid-cols-1 gap-4 md:gap-6 w-full',
            inPopover ? 'gap-2 md:gap-2' : 'xs:grid-cols-2 md:grid-cols-3',
          )}
          aria-label='Creation Mode'
        >
          {creationModeOptions
            .filter((e) => !e.hidden)
            .map((option) => {
              const { Icon, iconClassName } = option
              const needUpgrade = option.membersOnly && isFreePlan
              const isActive = mode === option.mode

              return (
                <div
                  key={option.mode}
                  className={cls(
                    'h-18 relative cursor-pointer pl-4 flex bg-white/5 rounded-xl items-center border border-b-2 border-solid border-border box-border hover:border-border-hover active:border-border-hover',
                    'max-w-[308px] hover:bg-surface-hover hover:border-border-hover',
                    isActive
                      ? 'cursor-default border-border-active hover:border-border-active bg-surface'
                      : '',
                    !option.available
                      ? 'hover:bg-white/5 active:bg-white/5 shadow-none hover:border-border cursor-not-allowed'
                      : '',
                  )}
                  aria-label='creation mode item'
                  onClick={(e: any) => {
                    e?.preventDefault?.()
                    e?.stopPropagation?.()
                    if (option.available) {
                      handleModeChange(option)
                    }
                  }}
                >
                  <div
                    className={cls(
                      'opacity-0 size-0 absolute',
                      option.available
                        ? 'pointer-events-auto'
                        : 'pointer-events-none',
                    )}
                  />
                  <div className='shrink-0 flex flex-row items-center w-full'>
                    <div
                      className={cls(
                        'size-10 p-2 rounded-full shrink-0 mr-3 text-icon-on-color',
                        needUpgrade ? 'opacity-50' : '',
                        iconClassName,
                      )}
                    >
                      <Icon
                        className={cls(!option.available ? 'opacity-50' : '')}
                        alt={option.name}
                      />
                    </div>
                    <div>
                      <div
                        className={cls(
                          'truncate',
                          needUpgrade ? 'text-text-disabled' : '',
                        )}
                      >
                        {option.name}
                      </div>
                      {option.newFeature ? (
                        <div className='flex items-center text-body-sm text-text-interactive'>
                          {option.newFeature}
                        </div>
                      ) : null}
                      {option.available ? null : (
                        <div className='text-text-subdued text-body-md ml-auto'>
                          Coming soon
                        </div>
                      )}
                    </div>
                    {option.membersOnly ? (
                      <div className='flex items-center gap-1 text-body-sm px-1 ml-auto mr-4'>
                        <Link
                          href='/membership'
                          className='leading-5 tracking-15 font-bold text-text-interactive'
                          onClick={stopPropagation as any}
                        >
                          Members
                        </Link>
                        <span className=''>only</span>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={cls(
                      'absolute right-2 inset-y-2 flex flex-col items-end gap-0.5',
                      option.isNew || option.isUpdated
                        ? 'justify-start'
                        : 'justify-center',
                    )}
                  >
                    {option.isNew ? <BadgeNew className='' /> : null}
                    {option.isUpdated ? <BadgeUpdate className='' /> : null}
                    {isActive ? <Checkbox checked className='mr-2' /> : null}
                  </div>
                </div>
              )
            })}
        </div>
      </section>
    </article>
  )
}
