import { useMemo } from 'react'
import useCredit from './useCredit'
import { CREATE_MODE_ALIAS } from '@/constants'
import { useAtomValue } from 'jotai'
import { creationInputAtom } from '@/atoms'

const useDurationOptions = () => {
  const { spendProductsList } = useCredit()
  const creationInput = useAtomValue(creationInputAtom)

  const durationOptions = useMemo(() => {
    const spendSpu = spendProductsList.find(
      (item) =>
        item.spu ===
        (CREATE_MODE_ALIAS as any)[creationInput?.mode || 'CreateHD'],
    )
    return spendSpu?.skus || []
  }, [spendProductsList, creationInput])

  return durationOptions
}

export default useDurationOptions
