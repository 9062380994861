import { useAtom } from 'jotai'
import { FC, ReactNode, forwardRef } from 'react'
import Button, { ButtonProps } from '@/components/button'
import { cls } from '@/utils'
import { creationInputAtom } from '@/atoms'
import ChevronDown from '@haiper/icons-svg/icons/outline/chevron-down-small.svg'
import useCreationModes from '@/hooks/useCreationModes'
// import { Badge } from '@/components/ui/badge'

export interface ButtonCreationModeProps extends ButtonProps {
  className?: string
  iconClassName?: string
  badgeClassName?: string
  iconOnly?: boolean
  name?: string
  Icon?: FC<{ className?: string }>
}

export const ButtonCreationMode = forwardRef<
  HTMLButtonElement,
  ButtonCreationModeProps
>(
  (
    {
      className,
      iconClassName,
      badgeClassName,
      iconOnly,
      Icon,
      name,
      ...props
    },
    ref,
  ) => {
    const [{ mode }] = useAtom(creationInputAtom)
    const { data: creationModeOptions } = useCreationModes()
    const selectedCreationMode =
      creationModeOptions.find((option) => option.mode === mode) ||
      creationModeOptions[0]

    const { Icon: ModeIcon, iconClassName: modeIconClassName } =
      selectedCreationMode ?? {}
    const RealIcon = Icon ?? ModeIcon

    return (
      <Button
        ref={ref}
        aria-label={name || selectedCreationMode.name}
        type='button'
        variant='outline'
        tooltip='Creation Mode'
        tooltipProps={{
          side: 'bottom',
        }}
        className={cls(
          'flex items-center justify-center size-10 p-2',
          iconOnly
            ? ''
            : 'flex justify-center gap-3 text-body-md cursor-pointer select-none text-text h-12 rounded-[48px] border-2 border-solid border-border transition-all duration-200 ease-in-out hover:border-border-hover active:border-border-hover bg-surface hover:bg-surface-hover active:bg-surface-hover hover:shadow-[0_0_10px_0_rgba(250,250,250,0.2)] active:shadow-[0_0_10px_0_rgba(250,250,250,0.2)]',
          'items-center relative',
          className,
        )}
        {...props}
        data-testid='creation-mode-button'
      >
        <div className='flex items-center gap-2'>
          <div
            className={cls(
              'size-10 p-0 rounded-full shrink-0 transition-all duration-200 ease-in-out opacity-100 text-icon-on-color relative flex items-center justify-center',
              iconOnly ? '' : 'size-6 p-1',
              modeIconClassName,
              iconClassName,
            )}
          >
            <RealIcon className={cls('size-6', iconOnly ? '' : 'size-4')} />
          </div>
          {iconOnly ? null : (
            <span
              aria-label='modeName'
              // className='text-heading-lg font-bold hidden md:block'
              className='text-body-md hidden md:block'
            >
              {name || selectedCreationMode.name}
            </span>
          )}
          {iconOnly ? null : <ChevronDown className='size-6 text-icon' />}
        </div>
      </Button>
    )
  },
)

ButtonCreationMode.displayName = 'ButtonCreationMode'
